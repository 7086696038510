import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const CardGrid = ({ gridItems }) => (
    <section className="cf w-100 pt3-ns">
    {gridItems.map(item => (
      <article className="fl w-100 w-100-m  w-33-ns pa2-ns" key={item.text}>
        <div className="aspect-ratio aspect-ratio--4x3">
            <PreviewCompatibleImage imageInfo={item.image} />
            <div className="f5 f4-ns fw7 mt3 mb0 black-90">{item.text}</div>
        </div>
      </article>
    ))}
  </section>
)

CardGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default CardGrid
