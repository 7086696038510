import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import Cards from '../components/Cards'

import CoverVideo from '../../static/video/coverr-manhattan-sunset-1571331549888.mp4'
import CoverImage from '../../static/img/coverr-manhattan-sunset-1571331549888.jpg'

export const IndexPageTemplate = ({
  title,
  mainpitch,
  intro,
  main,
}) => (
  <div>
    <div className="homepage-hero-module">
    <div className="video-container vh-50 vh-25-m vh-75-ns">
      <div className="filter"></div>
      <video autoPlay loop muted playsInline src={CoverVideo} className="fillWidth dn db-ns"></video>
      <div className="poster db dn-ns">
        <img src={CoverImage} alt="New York City sky scape" />
      </div>
    </div>
    <div className="cf">
      <div className="hero-content is-offset-1 fl w-100-m w-60-ns tc tl-ns f2 f3-m f1-ns fw7 column">
        {title}
      </div>
    </div>
  </div>
    <section className="section section--gradient">
      <div className="container">
        
          <div className="columns ph2-m ph6-ns">
            <div className="is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className="tile">
                    <h1 id="mission" className="title">{mainpitch.title}</h1>
                  </div>
                  <div>
                    <p>{mainpitch.description}</p>
                  </div>
                </div>
                <div className="content">
                  <div className="tile">
                    <h1 id="business-segments" className="title">{intro.title}</h1>
                  </div>
                </div>
                <Features gridItems={intro.blurbs} />

                <div className="content">
                  <div className="tile">
                    <h1 id="expertise" className="title">{main.heading}</h1>
                  </div>
                  <div>
                    <p>{main.description}</p>
                  </div>
                </div>
                <Cards gridItems={main.categories} />
              </div>
            </div>
          </div>
        
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    categories: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        main={frontmatter.main}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        mainpitch {
          title
          description
        }
        intro {
          title
          blurbs {
            title
            text
          }
          heading
          description
        }
        main {
          heading
          description
          categories {
            text
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
